label {
    font-size: 15px;
    color: #6c757d;
}

.rightContainer {
    background-color: #072673;
    height: 100%;
}

/*Own stiles*/
.ta-left {
    text-align: left !important;
}

.ta-right {
    text-align: right !important;
}

.ta-center {
    text-align: center !important;
}

.mr-10 {
    margin-right: 10px;
}

.mt-5 {
    margin-top: 5px;
}

.mt-6 {
    margin-top: 6px;
}

.mt-10 {
    margin-top: 10px;
}

.mt-20 {
    margin-top: 20px;
}

.mt-30 {
    margin-top: 30px;
}

.mt-40 {
    margin-top: 40px;
}

.ml-10 {
    margin-left: 10px;
}

.ml-40 {
    margin-left: 40px;
}

.mb-10 {
    margin-bottom: 10px;
}

.mb-20 {
    margin-bottom: 20px;
}

.padd-5 {
    padding: 5px;
}

.padd-40 {
    padding: 40px;
}

.padd-left-15 {
    padding-left: 15px;
}

.padd-left-50 {
    padding-left: 50px;
}

.padd-right-15 {
    padding-right: 15px;
}

.padd-right-50 {
    padding-right: 50px;
}

.padd-left-60 {
    padding-left: 60px;
}

.padd-right-60 {
    padding-right: 60px;
}

.m-t-20 {
    margin-top: 20px;
}

.m-l-10 {
    margin-left: 10px;
}

.m-l-13 {
    margin-left: 13px;
}

.width-110 {
    width: 110px;
}

.row-Wait {
    background-color: #e0e4ea !important;
}

.row-Pay {
    background-color: #c5dcff !important;
}

.row-OverPaid {
    background-color: #fff0c3 !important;
}

.row-Error {
    background-color: #d7b5b9 !important;
}

.row-No-Apply {
    background-color: #A0A0A0 !important;
}

.row-Pending {
    background-color: #ffba01 !important;
}

.row-Error {
    background-color: #e91224 !important;
}

.row-Closed {
    background-color: #34A835 !important;
}

.detail_table th, .detail_table td {
    border: 1px solid black;
    padding: 8px;
}

.datatable-crud-demo .table-header {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.height-100 {
    height: 100%;
}

.cf-card {
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0 2px 10px 0 #d8d8d8;
    width: 100%;
    line-height: 1;
    padding: 30px;
}

.smallFont {
    font-size: 13px;
}

.font-16 {
    font-size: 16px;
}

/**/
.dashboard .overview-box {
    padding: 15px;
    color: #ffffff;
    min-height: 100px;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    margin: 0 !important;
}
.dashboard .overview-box .overview-box-title {
    font-weight: bold;
    width: 100%;
}
.dashboard .overview-box .overview-box-title i {
    vertical-align: middle;
    font-size: 24px;
}
.dashboard .overview-box .overview-box-title span {
    margin-left: 0.5em;
    vertical-align: middle;
}
.dashboard .overview-box .overview-box-count {
    color: #ffffff;
    font-size: 24px;
    width: 100%;
    display: block;
    padding: 5px 0;
}
.dashboard .overview-box.overview-box-1 {
    background-color: #007bff;
    border: solid 1px #007bff;
    color: #ffffff;
}
.dashboard .overview-box.overview-box-2 {
    background-color: #28a745;
    border: solid 1px #28a745;
    color: #ffffff;
}
.dashboard .overview-box.overview-box-3 {
    background-color: #fd7e14;
    border: solid 1px #fd7e14;
    color: #ffffff;
}
.dashboard .overview-box.overview-box-4 {
    background-color: #6f42c1;
    border: solid 1px #6f42c1;
    color: #ffffff;
}
.dashboard .global-sales .p-panel-titlebar i {
    margin-right: 5px;
}
.dashboard .global-sales .p-panel-content {
    min-height: 291px;
    overflow: auto;
}
.dashboard .global-sales .p-panel-content {
    padding: 0;
}
.dashboard .global-sales table {
    width: 100%;
    border-collapse: collapse;
}
.dashboard .global-sales table th {
    font-weight: 700;
    text-align: left;
    padding: 10px 15px;
}
.dashboard .global-sales table tbody tr:nth-child(odd) {
    background: #f9f9f9;
}
.dashboard .global-sales table tbody tr img {
    width: 36px;
    height: 36px;
}
.dashboard .global-sales table tbody tr td {
    padding: 10px 15px;
}
.dashboard .global-sales table tbody tr td:nth-child(1) {
    font-weight: 700;
    text-align: center;
}
.dashboard .global-sales table tbody tr td:nth-child(3) {
    font-weight: 700;
}
.dashboard .product-statistics .p-panel-titlebar i {
    margin-right: 5px;
}
.dashboard .product-statistics .p-panel-content {
    min-height: 291px;
    overflow: auto;
}
.dashboard .product-statistics table {
    width: 100%;
    border-collapse: collapse;
}
.dashboard .product-statistics table td {
    padding: 10px 15px;
}
.dashboard .product-statistics table td.col-overview {
    max-width: 48px;
}
.dashboard .product-statistics table td.col-overview div {
    width: 45px;
    height: 45px;
    line-height: 45px;
    color: #ffffff;
    text-align: center;
    font-size: 24px;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
}
.dashboard .product-statistics table td.col-overview.col-wait div {
    background-color: #d9534f;
}
.dashboard .product-statistics table td.col-overview.col-success div {
    background-color: #5cb85c;
}
.dashboard .product-statistics table td.col-overview.col-delay div {
    background-color: #f0ad4e;
}
.dashboard .product-statistics table td.col-overview.col-preorder div {
    background-color: #2ea59f;
}
.dashboard .product-statistics table td.col-status .status-time {
    display: block;
    width: 100%;
    color: #777777;
}
.dashboard .product-statistics table td.col-status .status-text {
    font-weight: bold;
}
.dashboard .product-statistics table td.col-numbers {
    font-weight: bold;
}
.dashboard .product-statistics table td.col-numbers span {
    margin-left: 10px;
    color: #777777;
}
.dashboard .graphs > .p-grid .p-col-12 {
    padding-top: 0;
    padding-bottom: 0;
}
.dashboard .graphs > .p-grid .p-col-12:first-child {
    padding-left: 0;
}
.dashboard .graphs > .p-grid .p-col-12:last-child {
    padding-right: 0;
}
.dashboard .graphs .graph {
    background-color: #ffffff;
    border: solid 1px #dddddd;
    padding: 15px;
    width: 100%;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    min-height: 150px;
}
.dashboard .graphs .graph .graph-title {
    display: block;
    font-size: 16px;
    color: #777777;
}
.dashboard .graphs .graph .graph-value {
    display: block;
    margin: 10px 0;
    font-size: 24px;
    font-weight: bold;
}
.dashboard .graphs .graph .graph-change {
    color: #777777;
}
.dashboard .graphs .graph img {
    width: 100%;
}
@media (max-width: 1024px) {
    .dashboard .graphs > .p-grid .p-col-12 {
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 0.5em;
    }
}
.dashboard .task-list {
    overflow: hidden;
}
.dashboard .task-list .p-panel-content {
    padding: 0px !important;
    min-height: 366px;
}
.dashboard .task-list ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}
.dashboard .task-list ul li {
    padding: 10px 15px;
    border-bottom: 1px solid #e5e5e5;
}
.dashboard .task-list ul li:last-child {
    border-bottom: 0 none;
}
.dashboard .task-list ul .p-checkbox {
    vertical-align: middle;
    margin-right: 5px;
}
.dashboard .task-list ul .task-name {
    vertical-align: middle;
}
.dashboard .task-list ul i {
    color: #777777;
    float: right;
    font-size: 16px;
}
.dashboard .contact-form {
    overflow: hidden;
}
.dashboard .contact-form .p-panel .p-panel-content {
    min-height: 366px;
}
.dashboard .contact-form .p-col-12 {
    padding: 10px 15px;
}
.dashboard .contact-form .p-col-12:last-child {
    padding-bottom: 0;
}
.dashboard .contacts {
    overflow: hidden;
}
.dashboard .contacts .p-panel .p-panel-content {
    min-height: 366px;
    padding: 0px !important;
}
.dashboard .contacts ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}
.dashboard .contacts ul li {
    border-bottom: 1px solid #e5e5e5;
    padding: 10px 15px;
    width: 100%;
    box-sizing: border-box;
    text-decoration: none;
    position: relative;
    display: block;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    -moz-transition: background-color 0.2s;
    -o-transition: background-color 0.2s;
    -webkit-transition: background-color 0.2s;
    transition: background-color 0.2s;
}
.dashboard .contacts ul li img {
    float: left;
    margin-right: 8px;
}
.dashboard .contacts ul li .contact-info {
    float: left;
}
.dashboard .contacts ul li .contact-info .name {
    display: block;
    margin-top: 4px;
    font-size: 14px;
}
.dashboard .contacts ul li .contact-info .location {
    margin-top: 4px;
    display: block;
    font-size: 12px;
    color: #777777;
}
.dashboard .contacts ul li .contact-actions {
    float: right;
    padding-top: 12px;
}
.dashboard .contacts ul li .contact-actions .connection-status {
    color: #ffffff;
    padding: 2px 3px;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    border-radius: 4px;
}
.dashboard .contacts ul li .contact-actions .connection-status.online {
    background-color: #5cb85c;
}
.dashboard .contacts ul li .contact-actions .connection-status.offline {
    background-color: #d9534f;
}
.dashboard .contacts ul li .contact-actions .fa {
    color: #777777;
    margin-left: 5px;
}
.dashboard .contacts ul li:last-child {
    border: 0;
}
.dashboard .timeline {
    height: 100%;
    box-sizing: border-box;
}
.dashboard .timeline > .p-grid .p-col-3 {
    font-size: 14px;
    position: relative;
    border-right: 1px solid #e5e5e5;
}
.dashboard .timeline > .p-grid .p-col-3 i {
    background-color: #ffffff;
    font-size: 24px;
    position: absolute;
    top: 6px;
    right: -12px;
}
.dashboard .timeline > .p-grid .p-col-9 {
    padding-left: 1.5em;
}
.dashboard .timeline > .p-grid .p-col-9 .event-text {
    color: #777777;
    font-size: 14px;
    display: block;
    padding-bottom: 20px;
}
.dashboard .timeline > .p-grid .p-col-9 .event-content img {
    width: 100%;
}
.dashboard .chat .p-panel.no-pad .p-panel-content {
    padding: 0 !important;
}
.dashboard .chat .p-panel:last-child {
    margin-top: 15px;
}
.dashboard .chat ul {
    padding: 12px;
    margin: 0;
    list-style-type: none;
}
.dashboard .chat ul li {
    padding: 6px 0;
}
.dashboard .chat ul li img {
    width: 36px;
    float: left;
}
.dashboard .chat ul li span {
    padding: 6px 12px;
    float: left;
    display: inline-block;
    margin: 4px 0;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    border-radius: 4px;
}
.dashboard .chat ul li.message-from img, .dashboard .chat ul li.message-from span {
    float: left;
}
.dashboard .chat ul li.message-from img {
    margin-right: 8px;
}
.dashboard .chat ul li.message-from span {
    background-color: #dff0d8;
    border: solid 1px #d6e9c6;
}
.dashboard .chat ul li.message-own img, .dashboard .chat ul li.message-own span {
    float: right;
}
.dashboard .chat ul li.message-own img {
    margin-left: 8px;
}
.dashboard .chat ul li.message-own span {
    background-color: #d9edf7;
    border: solid 1px #bce8f1;
}
.dashboard .chat .new-message {
    height: 40px;
    border-top: 1px solid #e5e5e5;
    color: #afafc0;
}
.dashboard .chat .new-message .message-attachment {
    display: inline-block;
    border-right: 1px solid #e5e5e5;
    width: 40px;
    line-height: 40px;
    height: 100%;
    text-align: center;
}
.dashboard .chat .new-message .message-attachment i {
    line-height: inherit;
    font-size: 24px;
}
.dashboard .chat .new-message .message-input {
    position: relative;
    top: -4px;
    width: calc(100% - 100px);
    display: inline-block;
}
.dashboard .chat .new-message .message-input input {
    border: 0 none;
    font-size: 14px;
    width: 100%;
    background-color: transparent;
    outline: 0 none;
    padding-left: 8px;
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
}

.dashboard-banking .p-panel .panel-heading-desc {
    float: rigtht;
    color: #777777;
    font-size: 12px;
}
.dashboard-banking .overview-boxes .overview {
    height: 100%;
    box-sizing: border-box;
    min-height: 50px;
    border-radius: 4px;
    background-color: #457fca;
    background-image: -webkit-gradient(linear, left top, right top, from(#457fca), to(#5691c8));
    background-image: -webkit-linear-gradient(left, #457fca, #5691c8);
    background-image: -moz-linear-gradient(left, #457fca, #5691c8);
    background-image: -ms-linear-gradient(left, #457fca, #5691c8);
    background-image: -o-linear-gradient(left, #457fca, #5691c8);
    background-image: linear-gradient(to right, #457fca, #5691c8);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorStr="#457fca", endColorStr="#5691c8", gradientType="1");
}
.dashboard-banking .overview-boxes .overview-icon,
.dashboard-banking .overview-boxes .overview-text {
    display: block;
    float: left;
    margin-left: 5px;
}
.dashboard-banking .overview-boxes .overview-text span {
    display: block;
    height: auto;
}
.dashboard-banking .overview-boxes .overview-text {
    padding-top: 9px;
    padding-bottom: 9px;
}
.dashboard-banking .overview-boxes .overview-title {
    font-size: 12px;
    font-weight: 500;
    line-height: 15px;
    color: #d9edf7;
}
.dashboard-banking .overview-boxes .overview-subtitle {
    line-height: 16px;
    color: #ffffff;
}
.dashboard-banking .currency-panel {
    height: 100%;
}
.dashboard-banking .currency-panel .dashboard-currency {
    min-height: 85px;
    padding: 23px 10px;
    border: 1px solid rgba(67, 69, 96, 0.1);
    border-radius: 4px;
    background-color: #ffffff;
}
.dashboard-banking .currency-panel .dashboard-currency:after {
    content: "";
    display: table;
    clear: both;
}
.dashboard-banking .currency-panel .dashboard-currency img {
    width: 30px;
    height: 30px;
    object-fit: contain;
}
.dashboard-banking .currency-panel .dashboard-currency-icon,
.dashboard-banking .currency-panel .dashboard-currency-label {
    float: left;
}
.dashboard-banking .currency-panel .dashboard-currency-rates {
    float: right;
}
.dashboard-banking .currency-panel .dashboard-currency-rates .dashboard-currency-rate {
    float: left;
    margin-left: 15px;
    text-align: right;
}
.dashboard-banking .currency-panel .dashboard-currency-rates .dashboard-currency-rate:first-child {
    margin-left: 0;
}
.dashboard-banking .currency-panel .currency-rate-title {
    font-size: 12px;
    line-height: 20px;
    display: block;
    color: #777777;
}
.dashboard-banking .currency-panel .currency-rate-value {
    font-weight: 600;
    line-height: 17px;
    display: block;
}
.dashboard-banking .currency-panel .dashboard-currency-icon {
    margin-right: 10px;
    padding-top: 4px;
    padding-bottom: 4px;
}
.dashboard-banking .currency-panel .dashboard-currency-title {
    font-size: 17px;
    font-weight: 600;
    line-height: 22px;
    text-align: left;
}
.dashboard-banking .currency-panel .dashboard-currency-subtitle {
    font-size: 13.5px;
    font-weight: 500;
    color: #777777;
}
.dashboard-banking .currency-panel .currency-gold {
    margin-top: 8px;
}
.dashboard-banking .financial-overview {
    overflow: hidden;
}
.dashboard-banking .financial-overview .p-panel {
    height: 100%;
    border: 1px solid #DCE1E7;
}
.dashboard-banking .financial-overview .p-panel .p-panel-content {
    border: none;
}
.dashboard-banking .financial-overview .p-panel .p-widget-header {
    border: none;
    border-bottom: 1px solid #dddddd !important;
}
.dashboard-banking .financial-overview .financial-overview-total {
    line-height: 36px;
    padding: 17px 14px 15px 14px;
    border-bottom: 1px solid #ddd;
    background-color: #fbfbfb;
}
.dashboard-banking .financial-overview .financial-overview-total-label {
    float: left;
    font-weight: 600;
    color: #777;
    font-size: 16px;
}
.dashboard-banking .financial-overview .financial-overview-total-value {
    float: right;
    font-size: 19px;
    font-weight: bold;
    text-align: right;
    color: #337ab7;
}
.dashboard-banking .financial-overview .financial-overview-total-value::before {
    font-size: 14px;
    font-weight: bold;
    line-height: 17px;
    content: "$";
    color: #6ba6d9;
}
.dashboard-banking .financial-overview .financial-overview-line {
    line-height: 20px;
    margin-bottom: 4px;
}
.dashboard-banking .financial-overview .financial-overview-line .line-left {
    float: left;
    color: #777;
}
.dashboard-banking .financial-overview .financial-overview-line .line-right {
    float: right;
    text-align: right;
}
.dashboard-banking .financial-overview .financial-overview-line .line-icon,
.dashboard-banking .financial-overview .financial-overview-line .line-label,
.dashboard-banking .financial-overview .financial-overview-line .line-value {
    display: inline-block;
    margin-bottom: 8px;
}
.dashboard-banking .financial-overview .financial-overview-line .line-icon {
    display: inline-block;
    width: 16px;
    margin-right: 12px;
    text-align: center;
    font-size: 16px;
}
.dashboard-banking .financial-overview .financial-overview-line .line-value {
    font-weight: 600;
}
.dashboard-banking .financial-overview .financial-overview-line .line-caret {
    font-size: 16px;
    display: inline-block;
    margin-left: 10px;
    color: #777777;
}
.dashboard-banking .financial-overview .thumbnail img {
    display: block;
    width: 100%;
}
.dashboard-banking .financial-overview .progress {
    margin-top: 6px;
    margin-bottom: 6px;
    background-color: yellow;
}
.dashboard-banking .financial-overview .progress-explaination {
    font-size: 14px;
    line-height: 20px;
    color: #777777;
}
.dashboard-banking .financial-overview .button-link {
    line-height: 2em;
}
.dashboard-banking .financial-overview .panel-inner {
    padding: 15px;
}
.dashboard-banking .financial-overview .panel-inner.no-padding-top {
    padding-top: 0 !important;
}
.dashboard-banking .financial-overview .p-panel-content {
    padding: 0px !important;
    min-height: 225px;
}
.dashboard-banking .expenses.p-panel {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
}
.dashboard-banking .expenses .p-toggleable-content {
    height: 100%;
}
.dashboard-banking .expenses .p-panel-content {
    padding-top: 56px !important;
    position: relative;
    height: 100%;
}
.dashboard-banking .expenses .chart-expenses {
    padding-top: 10px;
    padding-left: 0px;
}
.dashboard-banking .expenses .chart-label {
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    margin-bottom: 3px;
    text-align: right;
}
.dashboard-banking .expenses .chart-label-name {
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 3px;
    text-align: right;
    color: #777777;
}
.dashboard-banking .expenses .chart-status {
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 16px;
    text-align: right;
}
.dashboard-banking .expenses .chart-status i {
    margin-right: 5px;
}
.dashboard-banking .expenses .status1 {
    color: #8ad88a;
}
.dashboard-banking .expenses .status2 {
    color: #de7470;
}
.dashboard-banking .expenses .shape, .dashboard-banking .expenses .shape3, .dashboard-banking .expenses .shape2, .dashboard-banking .expenses .shape1 {
    margin-right: 10px;
}
.dashboard-banking .expenses .shape1 {
    color: #d9edf7;
}
.dashboard-banking .expenses .shape2 {
    color: #6ba6d9;
}
.dashboard-banking .expenses .shape3 {
    color: #0154a3;
}
.dashboard-banking .messages .message {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 5px;
}
.dashboard-banking .messages .messager {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
}
.dashboard-banking .messages .messager-img {
    margin-top: 4px;
    margin-right: 10px;
    margin-bottom: 4px;
    border-left: 3px solid rgba(0, 0, 0, 0);
}
.dashboard-banking .messages .message-body {
    float: left;
    width: auto;
    width: 80%;
    max-width: 80%;
}
.dashboard-banking .messages .message-text {
    font-size: 14px;
    line-height: 20px;
    padding-right: 50px;
    text-align: left;
    color: #777777;
}
.dashboard-banking .messages .messager-img,
.dashboard-banking .messages .message {
    position: relative;
    display: inline-block;
    float: left;
}
.dashboard-banking .messages .message-box {
    padding-right: 15px;
}
.dashboard-banking .messages .message-box:hover {
    cursor: pointer;
}
.dashboard-banking .messages .message-box:hover .messager-img {
    margin-left: -15px;
    padding-left: 15px;
    border-left: 3px solid #337ab7;
}
.dashboard-banking .messages .online {
    margin-left: 5px;
    color: #8ad88a;
}
.dashboard-banking .messages .offline {
    margin-left: 5px;
    color: #de7470;
}
.dashboard-banking .messages .date {
    font-weight: 500;
    color: #ccc;
}
.dashboard-banking .asset-graph {
    width: 90%;
    margin-left: 5%;
    height: 240px;
}
.dashboard-banking .transaction {
    border-bottom: 1px solid yellow;
}
.dashboard-banking .transaction:last-child {
    border-bottom: none;
}
.dashboard-banking .transaction .transaction-title {
    font-weight: 500;
    display: block;
}
.dashboard-banking .transaction .transaction-subtitle {
    display: block;
    color: #777777;
}
.dashboard-banking .transaction .transaction-button {
    float: right;
    margin-top: 5px;
}
.dashboard-banking .assets.p-g-nopad .p-panel-content {
    padding: 0 !important;
}
.dashboard-banking .customer-support .support-estimation {
    float: left;
    margin-top: 10px;
}
.dashboard-banking .customer-support .support-estimation span {
    display: block;
}
.dashboard-banking .customer-support .form-contact {
    margin-top: 15px;
}
.dashboard-banking .customer-support .form-contact button {
    margin-top: 25px;
}
.dashboard-banking .customer-support .p-g-nopad .p-panel-content {
    padding: 0 !important;
}
.dashboard-banking .customer-support .support-top {
    width: 200px;
    margin: 0 auto;
    margin-bottom: 10px;
}
.dashboard-banking .customer-support .btn-padding {
    margin-top: 13px;
    margin-bottom: 17px;
}
.dashboard-banking .customer-support .p-grid-col-12 {
    width: 100%;
}
.dashboard .overview-box.overview-box-5 {
    background-color: #8dc8ff;
    border: solid 1px rgba(67, 69, 96, 0.1);
    color: #e9f4ff;
}

.dashboard .overview-box.overview-box-6 {
    background-color: #FFA726;
    border: solid 1px rgba(67, 69, 96, 0.1);
    color: #e9f4ff;
}

.dashboard .overview-box.overview-box-7 {
    background-color: #2ea59f;
    border: solid 1px rgba(67, 69, 96, 0.1);
    color: #e9f4ff;
}
/**/
